@import './reset.scss';
@import './variables.scss';
@import '../components/hooks/useParallax/useParallax.scss';

.App {
    overflow: hidden;
    scroll-behavior: smooth;

    .container {
        max-width: 80%;
        margin: 0 auto;
    }

    .content-section {
        max-width: 80%;
        margin: 0 auto;
        padding: 60px 0;

        .section-title {
            font-size: 32px;
            text-align: center;
            font-family: $main-font;
            font-weight: $fw-semibold;
            color: $text-strong;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                background: $main-green;
                height: 1px;
                width: 50px;
                bottom: -25%;
                left: 50%;
                transform: translate(-50%, 50%);
            }
        }

        .content-container {
            padding: 30px 0;
            color: $text;
            line-height: 1.8;
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: $fw-regular;

            .section-paragraph {
                margin: 0 0 10px 0;
            }

            .section-list {
                list-style: disc;
                margin: 0 0 10px 40px;
            }
        }
    }

    .section-columns {

        .content-container {
            display: flex;
            justify-content: space-between;
            text-align: center;
            flex-wrap: wrap;

            .button-container {
                width: 100%;
            }
        }

        .section-column {
            max-width: 30%;

            .column-subtitle {
                font-family: $main-font;
                font-weight: $fw-semibold;
                font-size: 20px;
                color: $text-strong;
                margin: 15px auto;
                text-transform: capitalize;
                text-align: center;
            }

            .item-link {
                color: $main-green;
                transition: $trasition;
        
                &:hover {
                    color: #767676;
                }
            }

            @media (max-width: $tablet-small) {
                max-width: none;
                width: 100%;

                .column-list {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}

