@import '../../../style/variables.scss';

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    .logo {
        cursor: pointer;
    }

    .footer-text {
        color: $text;
        font-size: 12px;
    }
}