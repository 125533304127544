@import '../../../style/variables.scss';

.section-columns {
    .section-column {
        .icon-link {
            max-height: 60px;
            padding: 20px;
            border: 1px solid $main-green;
            border-radius: 50%;
            margin: 20px auto;
            display: flex;
            max-width: 26px;
            cursor: pointer;

            .icon {
                max-width: 26px;
            }
        }
    }

    .button-container {
        margin: 60px 0;

        .column-button {
            margin: 30px auto;
            background: $main-green;
            color: $white;
            font-family: $main-font;
            font-weight: $fw-semibold;
            padding: 15px 35px;
            border-radius: 3px;
            transition: $trasition;
            border: 1px solid $main-green;
            cursor: pointer;

            &:hover {
                background: transparent;
                text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
                color: $main-green;
            }
        }
    }
}